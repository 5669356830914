import React, { Component } from "react";
import {
  FormControl,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { API } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import TEAMS from "../constants/Teams";

const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "1em",
  },
});

class TicketEvoInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeamId: "",
      selectedGame: {},
      teamSelected: false,
      gameSelected: false,
      games: null,
      totalListings: 0,
      listings: null,
      filter: false,
      confirmationOpened: false,
      selectedListing: {},
      isLoadingGame: false,
      isLoadingListing: false
    };
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleGameChange = this.handleGameChange.bind(this);
    this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
    // this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  getTeamId(teamSlug) {
    switch (teamSlug) {
      case "calgary-flames": {
        return 16415;
      }

      case "edmonton-oilers": {
        return 16417;
      }

      case "vancouver-canucks": {
        return 16419;
      }

      case "winnipeg-jets": {
        return 22681;
      }

      case "toronto-maple-leafs": {
        return 16404;
      }

      case "ottawa-senators": {
        return 16403;
      }

      case "toronto-raptors": {
        return 16305;
      }

      case "toronto-blue-jays": {
        return 15535;
      }

      case "edmonton-elks": {
        return 78171;
      }

      case "winnipeg-blue-bombers": {
        return 16300;
      }

      case "toronto-argonauts": {
        return 16295;
      }

      case "calgary-stampeders": {
        return 16297;
      }

      case "ottawa-redblacks": {
        return 41497;
      }

      case "bc-lions": {
        return 30587;
      }

      case "saskatchewan-roughriders": {
        return 16299;
      }

      case "hamilton-tigercats": {
        return 16292;
      }

      case "grey-cup": {
        return 13828;
      }

      case "calgary-wranglers": {
        return 83865;
      }

      case "manitoba-moose": {
        return 15191;
      }

      case "abbotsford-canucks": {
        return 15112;
      }

      case "vancouver-whitecaps-fc": {
        return 30243;
      }

      case "toronto-fc": {
        return 16206;
      }

      case "ufc-fight-night": {
        return 25507;
      }

      default: {
        return -1;
      }
    }
  }

  async handleTeamChange(e) {
    const teamId = e.target.value;
    this.setState({
      selectedTeamId: teamId,
      selectedGame: {},
      teamSelected: true,
      gameSelected: false,
      isLoadingGame: true
    });

    if (teamId === "") {
      return;
    }

    const { events } = await API.get(
      "v2",
      `marketplace/ticketEvo/events/index/by/performerId/${teamId}`
    );
    this.setState({
      // teamSelected: true,
      games: events,
      isLoadingGame: false
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  async handleGameChange(e) {
    const game = e.target.value;
    const gameId = game.id;
    this.setState({
      selectedGame: game,
      gameSelected: false,
      isLoadingListing: true
    });
    if (gameId === "") {
      return;
    }
    const { total_entries, ticket_groups } = await API.get(
      "v2",
      `marketplace/ticketEvo/listings/index/by/eventId/${gameId}`
    );
    this.setState({
      listings: ticket_groups,
      totalListings: total_entries,
      gameSelected: true,
      isLoadingListing: false
    });
  }

  /*   handleFilterChange = (e) => {
    this.setState({
      filter: e.target.checked,
    });
  }; */

  handlePurchase = async (listing) => {
    const { client, selectedGame } = this.state;
    console.log(client);
    console.log(listing);
    try {
      listing["purchasing"] = true;
      this.setState({ confirmationOpened: false });
      const { orders } = await API.post(
        "v2",
        "marketplace/ticketEvo/admin/orders/create",
        {
          body: {
            sellerId: listing.office.id,
            price: listing.retail_price,
            quantity: listing.purchaseQuantity,
            //ticketSignature: listing.signature,
            //ticketGroup: listing.signature,
            ticketGroupId: listing.id,
            orderType: this.getTicketType(listing.format),
            gameName: `${selectedGame.name} - ${selectedGame.occurs_at.split("T")[0]
              }`,
            zone: listing.section,
            row: listing.row,
          },
        }
      );
      console.log(orders);
      const { total_entries, ticket_groups } = await API.get(
        "v2",
        `marketplace/ticketEvo/listings/index/by/eventId/${selectedGame.id}`
      );
      this.setState({
        listings: ticket_groups,
        totalListings: total_entries,
      });
    } catch (error) {
      alert("Something went wrong. Please try again later.");
      listing["purchasing"] = false;
      this.setState({});
    }
  };

  handleConfirmationOpen = (listing) => {
    this.setState({
      selectedListing: listing,
      confirmationOpened: true,
    });
  };

  getTicketType(format) {
    switch (format) {
      case "TM_mobile":
        return "TMMobile";
      case "Flash_seats":
        return "FlashSeats";
      default:
        return format;
    }
  }

  renderForm() {
    const {
      selectedTeamId,
      selectedGame,
      teamSelected,
      games,
      isLoadingGame
    } = this.state;

    return (
      <React.Fragment>
        <form>
          <FormControl
            style={{
              minWidth: "175px",
              marginRight: "1.5rem",
              marginBottom: "1rem",
            }}
          >
            <InputLabel id="team-selector">Team</InputLabel>
            <Select
              id="team-selector"
              value={selectedTeamId}
              label="team"
              onChange={this.handleTeamChange}
              style={{ marginBottom: "1rem", marginRight: "1rem" }}
            >
              <MenuItem value={""}></MenuItem>
              {TEAMS.map(team => (
                <MenuItem key={team.homeTeamSlug} value={this.getTeamId(team.homeTeamSlug)}>
                  {team.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {teamSelected ? (
            <FormControl
              style={{
                minWidth: "300px",
                marginRight: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <InputLabel id="game-selector">Game</InputLabel>
              <Select
                id="game-selector"
                value={selectedGame}
                label="game"
                onChange={this.handleGameChange}
                style={{ marginRight: "1rem" }}
                disabled={isLoadingGame}
              >
                <MenuItem value={""}></MenuItem>
                {games && games.map((game) => (
                  <MenuItem value={game} key={game.id}>
                    {game.name} - {game.occurs_at.split("T")[0]}
                  </MenuItem>
                ))}
              </Select>
              {isLoadingGame && (
                <FormHelperText>Loading...</FormHelperText>
              )}
            </FormControl>
          ) : null}
          {/* {teamSelected && (
            <FormControlLabel
              control={
                <Switch
                  checked={filter}
                  onChange={this.handleFilterChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Filter"
            />
          )} */}
        </form>
      </React.Fragment>
    );
  }

  renderListings() {
    const { listings } = this.state;
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Notes</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Row</TableCell>
              <TableCell>Quantities Available</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Purchase Quantity</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings.map((listing) => this.renderRow(listing))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  renderRow(listing) {
    const { filter } = this.state;
    console.log(listing);

    if (filter) {
      if (isNaN(listing.row) || isNaN(listing.section)) {
        return null;
      }
    }

    return (
      <TableRow key={listing.id}>
        <TableCell>{listing.public_notes}</TableCell>
        <TableCell>{this.getTicketType(listing.format)}</TableCell>
        <TableCell>{listing.section}</TableCell>
        <TableCell>{listing.row}</TableCell>
        <TableCell>
          {listing.splits.map((quantity) => (
            <span key={quantity}>{quantity}</span>
          ))}
        </TableCell>
        <TableCell>${listing.retail_price}</TableCell>
        <TableCell>
          <Select
            fullWidth
            value={listing["purchaseQuantity"]}
            label="Quantity"
            onChange={(event) => {
              listing["purchaseQuantity"] = event.target.value;
              this.setState({});
            }}
          >
            {listing.splits.map((quantity) => (
              <MenuItem key={quantity} value={quantity}>
                <span>{quantity}</span>
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="secondary"
            disabled={!listing["purchaseQuantity"] || listing["purchasing"]}
            onClick={() => this.handleConfirmationOpen(listing)}
          >
            {listing["purchasing"] ? (
              <CircularProgress
                color="inherit"
                style={{ width: 16, height: 16 }}
              />
            ) : (
              "Buy"
            )}
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  handleConfirmationClose = () => {
    this.setState({
      confirmationOpened: false,
      selectedListing: {},
    });
  };

  renderConfirmation() {
    const { confirmationOpened, selectedListing, selectedGame } = this.state;
    if (!confirmationOpened) {
      return;
    }
    return (
      <Dialog open={confirmationOpened} onClose={this.handleConfirmationClose}>
        <DialogTitle>Confirm Purchase</DialogTitle>
        <DialogContent>
          <div style={{ paddingBottom: "1em" }}>
            <span>Confirming the purchase for </span>
            {""}
            <i>{selectedGame.name}</i>
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Game Date
              </td>
              <td style={{ textAlign: "right" }}>
                {selectedGame.occurs_at.split("T")[0]}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>Section</td>
              <td style={{ textAlign: "right" }}>{selectedListing.section}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>Row</td>
              <td style={{ textAlign: "right" }}>{selectedListing.row}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                Quantity
              </td>
              <td style={{ textAlign: "right" }}>
                {selectedListing.purchaseQuantity}
              </td>
            </tr>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleConfirmationClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => this.handlePurchase(selectedListing)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const { gameSelected, totalListings, isLoadingListing } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.header}>{this.renderForm()}</div>
        {isLoadingListing && (
          <Typography variant="h6" gutterBottom>
            Loading...
          </Typography>
        )}
        {gameSelected ? <span>Available Listings: {totalListings}</span> : null}
        <br></br>
        <br></br>
        {gameSelected && this.renderListings()}
        {this.renderConfirmation()}
      </div>
    );
  }
}

export default withStyles(styles)(TicketEvoInventory);
